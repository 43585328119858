import type { Message } from "ai";
import { z } from "zod";

export enum QuestionType {
  MULTIPLE_CHOICE = "multiple_choice",
  SELECT_ALL = "select_all",
  SHORT_ANSWER = "short_answer",
  TRUE_FALSE = "true_false",
  CLOZE = "cloze",
}

export type Tuuid = `${string}-${string}-${string}-${string}-${string}`;

export interface AnswerChoice {
  id: Tuuid;
  answerText: string;
  isCorrect: boolean;
}

interface BaseQuestionContent {
  questionText: string;
  explanation?: string | null;
}

export interface MultipleChoiceContent extends BaseQuestionContent {
  type: QuestionType.MULTIPLE_CHOICE;
  answerChoices: AnswerChoice[];
}

export interface SelectAllContent extends BaseQuestionContent {
  type: QuestionType.SELECT_ALL;
  answerChoices: AnswerChoice[];
}

export interface ShortAnswerContent extends BaseQuestionContent {
  type: QuestionType.SHORT_ANSWER;
  acceptableAnswers: string[];
}

export interface TrueFalseContent extends BaseQuestionContent {
  type: QuestionType.TRUE_FALSE;
  answerChoices: AnswerChoice[];
}

export interface ClozeContent extends BaseQuestionContent {
  type: QuestionType.CLOZE;
  acceptableAnswers: string[];
}

export type QuestionContent =
  | MultipleChoiceContent
  | SelectAllContent
  | ShortAnswerContent
  | TrueFalseContent
  | ClozeContent;

export interface MultipleChoiceResponse {
  type: QuestionType.MULTIPLE_CHOICE;
  selectedChoiceIds: Tuuid[];
}

export interface SelectAllResponse {
  type: QuestionType.SELECT_ALL;
  selectedChoiceIds: Tuuid[];
}

export interface ShortAnswerResponse {
  type: QuestionType.SHORT_ANSWER;
  answerText: string;
}

export interface TrueFalseResponse {
  type: QuestionType.TRUE_FALSE;
  selectedChoiceIds: Tuuid[];
}

export interface ClozeResponse {
  type: QuestionType.CLOZE;
  answerText: string;
}

export type ResponseContent =
  | MultipleChoiceResponse
  | SelectAllResponse
  | ShortAnswerResponse
  | TrueFalseResponse
  | ClozeResponse;

// Create a Zod schema for the Message type from the ai package
export const MessageSchema = z.any() as z.ZodType<Message>;
