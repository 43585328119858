import {
  assignmentQuestions,
  questions,
  questionState,
} from "supabase/drizzle/schema";
import { z } from "zod";
import { zuuid } from "~/utils/ids/uuid";
import {
  AnswerChoice,
  QuestionContent as IQuestionContent,
  QuestionType,
} from "../../supabase/drizzle/jsonTypes";

// Re-export QuestionType enum for compatibility
export { QuestionType } from "../../supabase/drizzle/jsonTypes";

// Basic Zod schemas
export const answerChoiceSchema = z.object({
  id: zuuid,
  answerText: z.string(),
  isCorrect: z.boolean(),
}) satisfies z.ZodType<AnswerChoice>;

export const baseQuestionSchema = z.object({
  questionText: z.string(),
  explanation: z.string().nullable().optional(),
});

// Question content schemas
export const multipleChoiceSchema = baseQuestionSchema.extend({
  type: z.literal(QuestionType.MULTIPLE_CHOICE),
  answerChoices: z.array(answerChoiceSchema).min(2),
});

export const selectAllSchema = baseQuestionSchema.extend({
  type: z.literal(QuestionType.SELECT_ALL),
  answerChoices: z.array(answerChoiceSchema).min(2),
});

export const shortAnswerSchema = baseQuestionSchema.extend({
  type: z.literal(QuestionType.SHORT_ANSWER),
  acceptableAnswers: z.array(z.string()).min(1),
});

export const trueFalseSchema = baseQuestionSchema.extend({
  type: z.literal(QuestionType.TRUE_FALSE),
  answerChoices: z.array(answerChoiceSchema).length(2),
});

export const clozeSchema = baseQuestionSchema.extend({
  type: z.literal(QuestionType.CLOZE),
  acceptableAnswers: z.array(z.string()).min(1),
});

export const questionContentSchema = z.discriminatedUnion("type", [
  multipleChoiceSchema,
  selectAllSchema,
  shortAnswerSchema,
  trueFalseSchema,
  clozeSchema,
]) satisfies z.ZodType<IQuestionContent>;

export const updateContentSchema = z.object({
  questionText: z.string().optional(),
  explanation: z.string().optional(),
  answerChoices: z.array(answerChoiceSchema).min(2).optional(),
  acceptableAnswers: z.array(z.string()).min(1).optional(),
});

// Question schema
export const questionSchema = z.object({
  id: z.number(),
  questionContent: questionContentSchema,
  createdBy: zuuid,
  state: z.enum(questionState.enumValues),
  createdAt: z.date(),
  updatedAt: z.date(),
  knowledgeComponentId: z.number().nullable().default(null),
  aiGenerated: z.boolean().default(false),
});

// Completed question schemas
const completedAnswerChoiceSchema = z.object({
  id: zuuid,
  answerText: z.string().min(1, "Answer text is required"),
  isCorrect: z.boolean(),
});

const completedBaseQuestionSchema = z.object({
  questionText: z.string().min(1, "Question text is required"),
  explanation: z.string().nullable().optional(),
});

const completedMultipleChoiceSchema = completedBaseQuestionSchema.extend({
  type: z.literal(QuestionType.MULTIPLE_CHOICE),
  answerChoices: z
    .array(completedAnswerChoiceSchema)
    .min(2, "At least two answer choices are required")
    .refine(
      (choices) => choices.some((choice) => choice.isCorrect),
      "At least one answer must be marked as correct",
    ),
});

const completedSelectAllSchema = completedBaseQuestionSchema.extend({
  type: z.literal(QuestionType.SELECT_ALL),
  answerChoices: z
    .array(completedAnswerChoiceSchema)
    .min(2, "At least two answer choices are required")
    .refine(
      (choices) => choices.some((choice) => choice.isCorrect),
      "At least one answer must be marked as correct",
    ),
});

const completedShortAnswerSchema = completedBaseQuestionSchema.extend({
  type: z.literal(QuestionType.SHORT_ANSWER),
  acceptableAnswers: z
    .array(z.string().min(1, "Acceptable answer cannot be empty"))
    .min(1, "At least one acceptable answer is required"),
});

const completedTrueFalseSchema = completedBaseQuestionSchema.extend({
  type: z.literal(QuestionType.TRUE_FALSE),
  answerChoices: z
    .array(completedAnswerChoiceSchema)
    .length(2, "True/False questions must have exactly two choices")
    .refine(
      (choices) => choices.some((choice) => choice.isCorrect),
      "One answer must be marked as correct",
    ),
});

const completedClozeSchema = completedBaseQuestionSchema.extend({
  type: z.literal(QuestionType.CLOZE),
  acceptableAnswers: z
    .array(z.string().min(1, "Acceptable answer cannot be empty"))
    .min(1, "At least one acceptable answer is required"),
});

export const completedQuestionContentSchema = z.discriminatedUnion("type", [
  completedMultipleChoiceSchema,
  completedSelectAllSchema,
  completedShortAnswerSchema,
  completedTrueFalseSchema,
  completedClozeSchema,
]);

export const completedQuestionSchema = questionSchema.extend({
  questionContent: completedQuestionContentSchema,
});

// Inferred types
export type Answer = z.infer<typeof answerChoiceSchema>;
export type QuestionContent = z.infer<typeof questionContentSchema>;
export type Question = z.infer<typeof questionSchema>;
export type CompletedQuestion = z.infer<typeof completedQuestionSchema>;

// Types inferred from Drizzle schema
export type SelectQuestion = typeof questions.$inferSelect;
export type SelectQuestionWithFlags = SelectQuestion & {
  questionFlags: {
    id: number;
    status: "use" | "edit" | "drop";
    notes: string | null;
    aiGenerated: boolean;
    isResolved: boolean;
  }[];
};

export type AssignmentQuestion = Omit<
  typeof assignmentQuestions.$inferSelect,
  "createdAt" | "updatedAt"
> & {
  question: Omit<SelectQuestion, "questionContent" | "state"> & {
    questionContent: Question["questionContent"];
  };
};
